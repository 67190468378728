import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/assessment/:id',
    name: 'Assessment',
    component: () => import(/* webpackChunkName: "assessment" */ '../views/Assessment.vue'),
    props: true
  },
  {
    path: '/summary',
    name: 'Summary',
    component: () => import(/* webpackChunkName: "summary" */ '../views/Summary.vue')
  },
  {
    path: '/sorry',
    name: 'Sorry',
    component: () => import(/* webpackChunkName: "sorry" */ '../views/Sorry.vue')
  },
  
  {
    path: '/result',
    name: 'Result',
    component: () => import(/* webpackChunkName: "result" */ '../views/Result.vue')
  },

  {
    path: '/bookmeeting',
    name: 'BookMeeting',
    component: () => import(/* webpackChunkName: "bookmeeting" */ '../views/BookMeeting.vue')
  },

  {
    path: '/thankyou',
    name: 'Thankyou',
    component: () => import(/* webpackChunkName: "thankyou" */ '../views/Thankyou.vue')
  },
  
 
  // {
  //   path: '/admin',
  //   name: 'Admin',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
  //   children: [
  //     {
  //       path: 'home',
  //       name: 'AdminHome',
  //       component: () => import(/* webpackChunkName: "adminqlib" */ '../views/AdminHome.vue')
  //     },
  //     {
  //       path: 'flows',
  //       name: 'AdminFlow',
  //       component: () => import(/* webpackChunkName: "adminflows" */ '../views/AdminFlow.vue')
  //     }
  //   ]
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
