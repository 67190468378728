<template>
  <div>
   
    <div id="header">
        <img src="../public/anlo_logo_white.svg" alt="ANLO Assist - ANLO Financial Solutions" title="ANLO Assist" style="width: 150px;"/> 
    </div>

    <router-view />

    <div id="footer">
            <p>
        <strong>ANLO FINANCIAL SOLUTIONS EDINBURGH LTD</strong><br />
    <a href="tel:+441313708028" class="acolour">+44 131 370 8028</a> | <a href="mailto:CONTACT@ANLOFIN.COM" class="acolour">CONTACT@ANLOFIN.COM</a><br /><br />
    <a href="https://www.bytesizeitsolutions.com"
              target="_blank"
              class="acolour">Site Made by Byte Size IT Solutions</a>
                </p>
    </div>

  </div>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>

  .acolour {
    color: #ffffff;
    font-family: 'Merriweather', serif ;
  }
    body {
    background: #c71617 ;
    padding: 0px ;
    margin: 10px ;
    color: #fff ;
        font-family: 'Merriweather', serif ;
}
    
    h1 {
        
        font-size: 30px ;
        font-weight: 900 ;
        color: #c71617 ;
    }
    
    h2 {
        
        font-size: 24px ;
        font-weight: 400 ;
        color: #000 ;
        line-height: 36px ;
    }
    
    #header  {
        
    text-align: center ;
    margin: 0 auto ;
    max-width: 1000px ;
   
}  

.center_box { 
    
    max-width: 1000px ;
    margin:  0 auto ;
    padding: 30px ;
    
    min-height: auto ;
    border-radius: 25px ;
    background: #ffffff ;
    overflow: hidden ;
    
    
}

textarea {
    color: #000 ;
    font-family: 'Merriweather', serif ; 
    line-height: 30px ;
}
    
    .center_box p {
        
        color: #000 ;
        font-family: 'Merriweather', serif ; 
        line-height: 30px ;
    }   
    
    .center_box ul li {
        
        list-style: square ;
        line-height: 30px ;
        margin-left: 10px ;
        color: #000 ;
        margin-bottom: 10px ;
    }
    
    #footer {
        
        max-width: 1000px ;
        margin: 0 auto ;
        padding: 20px ; 
        text-align: center ;
    }
    #footer p {
        font-weight: 300 ;
        font-size: 14px ;
    }
    
    button {
        
        background: #8e1818 ;
        border-color: #fff ;
        padding: 10px ;
        min-width: 100px ;
        color: #fff ;
        border-radius: 25px ;
        font-family: 'Merriweather', serif ;
    }

    button:disabled {
      background: #555555;
    }

    .align_right {
      float: right;
      display: block;
    }

    label {
      color: #000;
      padding-right: 5px;
    }

    input {
        
        background: #8e1818;
        border-color: #fff;
        padding: 10px;
        
        color: #fff;
        border-radius: 25px;
        font-family: 'Merriweather', serif;
    }


.more_info {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 15px;
  background-color: white;
  display: inline-block;
  margin-left: 15px;
}

.more_info_option {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 15px;
  background-color: white;
  display: inline-block;
}

</style>