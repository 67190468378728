<template>
  <div class="loading_box">
      <div class="container">
        <div class="loader"></div>
      </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

.loading_box { 
    
    max-width: 1000px ;
    margin:  0 auto ;
    padding: 30px ;
    
    min-height: auto ;
    border-radius: 25px ;
    background: #ffffff ;
    overflow: hidden ;
    
    
}
/* loading animator */

.container {
  padding: 120px 0;
  text-align: center;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #bbbbbb;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    align-content: center;
}

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
</style>