<template>

  <div>
    <!-- Loading screen -->
    <loading-progress v-if="loading" />
    <!-- main content -->
    <div class="center_box" v-else>
    
    <h1>United Kingdom Tax Residency Test</h1>
        <h2>Anlo Assist is for anyone who has struggled to understand His Majesty’s Revenue & Custom (HMRC) website on tax residency. I moved from South Africa to the United Kingdom, and trying to understand the split year treatments, Sufficient Ties Test and the domicile rules just did my head in, and I’m an accountant!</h2>
    
        
        <ul>
            <li>Have you just arrived in the United Kingdom and want to know if you are deemed tax resident and need to think about your tax affairs in the United Kingdom?</li>
            <li>Do you travel extensively for business, spending time in the UK and abroad?</li>
            <li>Are you worried that you might be due tax in two (or more) jurisdictions and unsure where you are tax resident?</li>
            <li>Does your uncertainty leave you worried that you are not acting on your tax obligation in the United Kingdom?</li>
            
            </ul>
    
        <p><strong>Anlo Assist uses a series of specific questions to quickly assess the probability that you are a UK resident for tax purposes. It will not give you an absolute answer because tax affairs are complicated, but it will indicate with a high degree of accuracy if you are likely to be regarded as resident or not.
          
          </strong><br>
          <br>
          Once you have that answer you can decide whether or not you’d like to speak to one of our colleagues in order to reach a definitive understanding of your tax position. Only if you do that will any of your information be retained under the terms of our Data policy.
          
          <br>
          <br>
          If you think you might be dual residence, please feel free to use this app, but remember that a double taxation agreement might need to be considered in your tax affairs, and I would encourage any person with dual residence (residence in two countries), to seek further advice.
          
          If your circumstances change, you will need to look again at your status.
          
          <br>
          <br>
      Finally - your tax status depends on the facts of your individual case - if you need more advice please speak to one of our advisors.</p>
      
      <div>

        <div class="align_right">
        <label for="acceptTerms" >
            Please read and accept our
              <template>
                <a
                  target="_blank"
                  href="https://www.anlofin.com/anlo-assist-tc"
                  @click.stop
                >
                  Terms
                </a>
                & 
                <a
                  target="_blank"
                  href="https://www.anlofin.com/privacy-policy-anlo-assist"
                  @click.stop
                >
                  Privacy Policy
                </a>
                to continue
              </template>
        </label>
        <input type="checkbox" id="acceptTerms" v-model="accepted" >
        </div>
        <br>
        <br>

        <button type="button" :disabled="!accepted" class="align_right" @click.stop="proceed()"><span>Start</span></button>

      </div>

    </div>
  </div>
</template>

<script>
import LoadingProgress from '../components/LoadingProgress.vue'
export default {
  name: "Home",
  components: {LoadingProgress},
  data: () => ({
    accepted: false,
    errmsg: '',
    next: null,
    loading: false
  }),
  methods: {
    proceed() {

      this.loading = true
      localStorage.clear()

      fetch(process.env.VUE_APP_API_BASE_URL + 'flows?id=start&choice=*')
      .then(res => res.json())
      .then(data => {
        //this.next = data[0].next
        this.next = data.next
        // unique_id from the start call will be treated like session.
        // so, let's store it in local storage.
        localStorage.setItem('unique_id',data.unique_id)
        this.$router.push({name: 'Assessment', params: {id:this.next}})
      })
      .catch(err => this.errmsg = err.message)
    }
  },
};
</script>

<style>


</style>